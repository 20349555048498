import { getFirestore, collection, getDocs, onSnapshot, doc, getDoc } from "firebase/firestore"

export const getCustomer = async (customerId) => {
    return new Promise((resolve, reject)=>{

        const db = getFirestore();
        const path = "customers/" + customerId;
        const q = doc(db, path);

        getDoc(q).then(snapshot => {
            let d = snapshot.data();
            if(d) d.id = snapshot.id;
            resolve(d);

        }).catch((error)=>{
            //console.error("There was a problem retrieving the data for customerId " +customerId , error);
            reject(error);
        })
    });
}

export const getCheckoutsForUser = async (userId) => {
    const db = getFirestore();
    const ref = collection(db, "customers", userId, "checkouts");

    const snapShot = await getDocs(ref);
    const docs = [];
    snapShot.forEach((d) => {
        docs.push({ ...d.data(), id: d.id });
    });

    return docs;
}

export const getBoxesForUser = async (userId) => {
    const db = getFirestore();
    const ref = collection(db, "customers", userId, "boxes");

    const snapShot = await getDocs(ref);
    const docs = [];
    snapShot.forEach((d) => {
        docs.push({ ...d.data(), id: d.id });
    });

    return docs;
}

export const listenForChangesInBoxesCustomer = (userId, callback) => {
    const db = getFirestore();
    const ref = doc(db, "customers", userId);

    const unsubscribe = onSnapshot(ref, (doc) => {
        callback(doc.data());
    });

    return unsubscribe;
}

export const listenForChangesInBoxesCollection = (userId, callback) => {
    const db = getFirestore();
    const ref = collection(db, "customers", userId, "boxes");

    const unsubscribe = onSnapshot(ref, (snapShot) => {
        const docs = [];
        snapShot.forEach((d) => {
            docs.push({ ...d.data(), id: d.id });
        });

        callback(docs);
    });

    return unsubscribe;
}

export const listenForChangesInCustomerPostIdent = (userId, callback) => {
    const db = getFirestore();
    const ref = doc(db, "postidents", userId);

    const unsubscribe = onSnapshot(ref, (doc) => {
        callback(doc.data());
    });

    return unsubscribe;
}
