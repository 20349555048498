import { useEffect, useState } from 'react';
import {getCheckoutsForUser, listenForChangesInBoxesCustomer, listenForChangesInCustomerPostIdent} from "../services/FirestoreService";

export default function useCustomer(userId) {

    const [customer, setCustomer] = useState();
    const [postIdent, setPostIdent] = useState();
    const [checkouts, setCheckouts] = useState();

    useEffect( () =>{
        if(!userId) return;

        const unsubscribeCustomer = listenForChangesInBoxesCustomer(userId, (data) => {
            if(data) {
                setCustomer(data);
            }
        });

        const unsubscribePostIdent = listenForChangesInCustomerPostIdent(userId, (data) => {
            if(data) {
                setPostIdent(data);
            }
        });

        return () => {
            unsubscribeCustomer();
            unsubscribePostIdent();
        }

    }, [userId]);

    useEffect(() => {

        if(!customer) return;

        const loadData = async () => {
            try {
                const checkoutData = await getCheckoutsForUser(userId);
                setCheckouts(checkoutData);
            } catch (error) {
                //console.log("There was an error, getting the checkoutData: ", error);
            }
        }

        loadData();

    }, [customer, userId]);

    return {customer, checkouts, postIdent};
}
