import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import FunctionsApiService from "services/FunctionsApiService";

import {
    isValidCity,
    isValidHouseNr,
    isValidPostcode,
    isValidSreetname,
} from "concerns/checkout/services/InputValidationService";

import { ButtonPrimary } from "@asservato/shared.ui.button-primary";
import TextInput from "components/TextInput";
import { Select } from "@asservato/shared.ui.select";
import { LoadingIndicator } from "@asservato/shared.ui.loading-indicator";

import { LabelRegularSmall, Title } from "styles/fontStyles";
import { colors } from 'styles/colors';
import * as S from 'styles/sharedStyles';
import {useAppState} from "contexts/AppStateContext";
import {IconButton} from "@mui/material";
import * as Icon from "react-feather";
import {useHistory} from "react-router-dom";
import {objectsAreEqual} from "concerns/checkout/services/Utils";
import {CheckBox} from "@asservato/shared.ui.checkbox";
import {countries} from "concerns/checkout/constants/Countries";
import {useSnackbar} from "notistack";

export default function EditAddressView() {

    const { customer } = useAppState();
    const { t } = useTranslation();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [formData, setFormData] = useState();
    const [isLoading, setIsLoading] = useState();
    const [displayBillingAddress, setDisplayBillingAddress] = useState();

    const countriesArray = Object.keys(countries).map(country => {
        const languageCode = 'de' // TODO: re-activate once the country list has been translated -> i18n.language.split('-')[0];
        return {
            value: country,
            label: countries[country][languageCode]
        };
    });

    useEffect(()=>{
        if(!customer) return;
        setFormData(customer);
        setDisplayBillingAddress(!objectsAreEqual(customer?.address, customer?.billingAddress));
    }, [customer])

    useEffect(()=>{
        if(!customer || displayBillingAddress === undefined) return;
        if(displayBillingAddress){
            if(objectsAreEqual(customer.address, customer.billingAddress)){
                updateFormData({
                    billingAddress: {
                        appendix: null,
                        street: '',
                        streetNo: '',
                        zip: '',
                        city: '',
                        country: '',
                    }
                })
            }
        } else {
            updateFormData({
                billingAddress: customer.address
            })
        }

    }, [displayBillingAddress, customer])

    const updateFormData = (data) => {
        setFormData(prevState => {
            return {...prevState, ...data}
        });
    }

    const onClose =  useCallback(()=> {
        history.goBack();
    }, [history])

    const onUpdateCustomerData = useCallback(async () => {

        try {
            setIsLoading(true);
            await FunctionsApiService.customersUpdate(formData);
            setIsLoading(false);
            onClose();
            enqueueSnackbar(t('saveChangesSuccess'),{ variant: 'success' });

        } catch (error) {
            enqueueSnackbar(t('error'),{ variant: 'error' });
            setIsLoading(false);
        }

    }, [formData, setIsLoading, onClose, enqueueSnackbar, t]);

    if(!formData) return null;

    const isAddressValid =
        isValidSreetname(formData.address.street) &&
        isValidHouseNr(formData.address.streetNo) &&
        isValidPostcode(formData.address.zip) &&
        isValidCity(formData.address.city) &&
        !!formData.address.country;

    const isBillingAddressValid = formData.billingAddress &&
        isValidSreetname(formData.billingAddress.street) &&
        isValidHouseNr(formData.billingAddress.streetNo) &&
        isValidPostcode(formData.billingAddress.zip) &&
        isValidCity(formData.billingAddress.city) &&
        !!formData.billingAddress.country;

    const isFormValid =
        (!displayBillingAddress && isAddressValid) ||
        (displayBillingAddress && isAddressValid && isBillingAddressValid);

    return (
        <S.View>

            <Header>
                <Title>
                    {t('editAddress.title')}
                </Title>

                <CloseButtonContainer>
                    <IconButton
                        variant="contained"
                        size="small"
                        onClick={onClose}
                    >
                        <Icon.X color={colors.primary.primary_300} size={24}/>
                    </IconButton>
                </CloseButtonContainer>
            </Header>

            <FormSection marginTop={'24px'}>

                <SectionLabel>Lieferadresse für Kundenkarte</SectionLabel>
                <FormRow>
                    <TextInput label={t('enterAddressDetails.street')}
                               disabled={false} error={false}
                               onChange={(e)=>updateFormData({address:{...formData.address, street:e.target.value}})}
                               value={formData.address.street}
                               width={'75%'}
                               isValid={isValidSreetname(formData.address.street)}
                               name={"street-address"}
                    />
                    <HorizontalSeparator/>
                    <TextInput label={t('enterAddressDetails.streetNumber.placeholder')}
                               disabled={false} error={false}
                               onChange={(e)=>updateFormData({address:{...formData.address, streetNo:e.target.value}})}
                               value={formData.address.streetNo}
                               width={'25%'}
                               isValid={isValidHouseNr(formData.address.streetNo)}
                               name={"houseNr"}
                    />
                </FormRow>

                <FormRow marginTop={'16px'}>
                    <TextInput label={t('enterAddressDetails.postcode')}
                               disabled={false}
                               error={false}
                               onChange={(e)=>updateFormData({address:{...formData.address, zip:e.target.value}})}
                               value={formData.address.zip}
                               width={'25%'}
                               isValid={isValidPostcode(formData.address.zip)}
                               name={"postal-code"}
                    />
                    <HorizontalSeparator/>
                    <TextInput label={t('enterAddressDetails.city')}
                               disabled={false} error={false}
                               onChange={(e)=>updateFormData({address:{...formData.address, city:e.target.value}})}
                               value={formData.address.city}
                               width={'75%'}
                               isValid={isValidCity(formData.address.city)}
                               name={"city"}
                    />
                </FormRow>
            </FormSection>

            <Select label={t('enterAddressDetails.country')}
                    disabled={false}
                    error={false}
                    margin={'16px 0 0 0'}
                    options={countriesArray}
                    onChange={(e)=>updateFormData({address:{...formData.address, country:e.target.value}})}
                    value={formData.address.country}
                    isValid={formData.address.country?.length > 0}
            />

            <FormRow marginTop={"24px"}>
                <CheckBox size={"small"} checked={displayBillingAddress} onChange={(e)=>setDisplayBillingAddress(e.target.checked)}/>
                <Label>Die Rechnungsadresse weicht von der Lieferadresse ab</Label>
            </FormRow>

            {displayBillingAddress
                ?   <>
                    <FormSection marginTop={'24px'}>

                        <SectionLabel>Rechnungsadresse</SectionLabel>
                        <FormRow>
                            <TextInput label={t('enterAddressDetails.street')}
                                       disabled={false} error={false}
                                       onChange={(e)=>updateFormData({billingAddress:{...formData.billingAddress, street:e.target.value}})}
                                       value={formData.billingAddress?.street}
                                       width={'75%'}
                                       isValid={isValidSreetname(formData.billingAddress?.street)}
                                       name={"street-address"}
                            />
                            <HorizontalSeparator/>
                            <TextInput label={t('enterAddressDetails.streetNumber.placeholder')}
                                       disabled={false} error={false}
                                       onChange={(e)=>updateFormData({billingAddress:{...formData.billingAddress, streetNo:e.target.value}})}
                                       value={formData.billingAddress?.streetNo}
                                       width={'25%'}
                                       isValid={isValidHouseNr(formData.billingAddress?.streetNo)}
                                       name={"houseNr"}
                            />
                        </FormRow>

                        <FormRow marginTop={'16px'}>
                            <TextInput label={t('enterAddressDetails.postcode')}
                                       disabled={false}
                                       error={false}
                                       onChange={(e)=>updateFormData({billingAddress:{...formData.billingAddress, zip:e.target.value}})}
                                       value={formData.billingAddress?.zip}
                                       width={'25%'}
                                       isValid={isValidPostcode(formData.billingAddress?.zip)}
                                       name={"postal-code"}
                            />
                            <HorizontalSeparator/>
                            <TextInput label={t('enterAddressDetails.city')}
                                       disabled={false} error={false}
                                       onChange={(e)=>updateFormData({billingAddress:{...formData.billingAddress, city:e.target.value}})}
                                       value={formData.billingAddress?.city}
                                       width={'75%'}
                                       isValid={isValidCity(formData.billingAddress?.city)}
                                       name={"city"}
                            />
                        </FormRow>
                    </FormSection>

                    <Select label={t('enterAddressDetails.country')}
                            disabled={false}
                            error={false}
                            margin={'16px 0 0 0'}
                            options={countriesArray}
                            onChange={(e)=>updateFormData({billingAddress:{...formData.billingAddress, country:e.target.value}})}
                            value={formData.billingAddress?.country}
                            isValid={formData.billingAddress?.country?.length > 0}
                    />
                </>
                : null

            }

            {!isLoading
                ? <ButtonPrimary isDisabled={!isFormValid}
                                 label={t('save')}
                                 marginTop={32}
                                 onClick={onUpdateCustomerData}
                />
                : <LoadingIndicator marginTop={24}/>
            }

        </S.View>
    );
}

const Header = styled.div`
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 16px;
`;

const FormSection = styled.div`
    margin-top: ${props => props.marginTop || null};
`;

const FormRow = styled.div`
    margin-top: ${props => props.marginTop || null};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const HorizontalSeparator = styled.div`
    min-width: 12px;
    flex: 1;
`;

const SectionLabel = styled(LabelRegularSmall)`
    display: block;
    padding-bottom: 12px;
`;

const Label = styled(LabelRegularSmall)`
    a {
        color: ${colors.tertiary.tertiary_100};
    }
`;

const CloseButtonContainer = styled.div`
    display: block;
    position:absolute;
    right:16px;
    top:16px;
`;
