import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import * as Sentry from "@sentry/browser";
import {useCheckoutState} from "concerns/checkout/contexts/CheckoutStateContext";
import {useTranslation} from "react-i18next";
import trackEvent, {EVENTS} from "../services/Tracking";
import FunctionsApiService from "services/FunctionsApiService";

import {
    isValidEmail,
    isValidFirstName,
    isValidLastName,
    isValidPhonenumber,
} from "../services/InputValidationService";

import { PageHeaderLinearFlow } from "@asservato/shared.ui.page-header-linear-flow";
import { ButtonPrimary } from "@asservato/shared.ui.button-primary";
import TextInput from "../../../components/TextInput";
import Select from "../../../components/Select";
import { LoadingIndicator } from "@asservato/shared.ui.loading-indicator";
import { DialogBasic } from "@asservato/shared.ui.dialog-basic";

import { LabelRegularSmall } from "../styles/fontStyles";
import { colors } from '../styles/colors';
import * as S from '../styles/sharedStyles';
import { registerUser } from "../services/AuthenticationService";
import { generateRandomString } from "../services/Utils";
import { signupPaths } from "navigation/SignupRoutes";
import { useAppState } from "contexts/AppStateContext";
import {useHistory} from "react-router-dom";

const DIALOG_TYPE = {
    TEMPORARY_USER: "TEMPORARY_USER",
    EXISTING_USER: "EXISTING_USER"
}

export default function EnterContactDetailsView({ rootPath, prevPath, nextPath, index }) {

    const { formData, updateFormData, activeStep, totalNumSteps, progressBrackets, updateActiveStep, setErrorMessage } = useCheckoutState();
    const { customer } = useAppState();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [showDialogType, setShowDialogType] = useState();
    const history = useHistory();

    useEffect(() => {
        updateFormData({heroImage:"../assets/checkout/hero_1.jpg"});
        updateActiveStep(index);

        // only track events in signup
        if(rootPath === signupPaths.signupRoot) {
            trackEvent(EVENTS.PLAN_SELECTED, "plan", formData.selectedPlan?.interval.unit);
        } else {
            // pre-fill form data when not in signup (eg. partner registration)
            if(customer){
                updateFormData({
                    professionalTitle: customer.title || '',
                    salutation: customer.salutation || '',
                    firstName: customer.firstName || '',
                    lastName: customer.lastName || '',
                    company: customer.company || '',
                    address: customer.address || '',
                    billingAddress: customer.billingAddress || '',
                    email: customer.email || '',
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateFormData])

    const onUpdateCustomerData = useCallback(async () => {
        try {
            let customerPayload =   {
                title: formData.professionalTitle,
                salutation: formData.salutation,
                firstName: formData.firstName,
                lastName: formData.lastName,
                company: formData.company,
                address: formData.address,
                billingAddress: formData.billingAddress,
                email: formData.email,
                mobilePhone: formData.phone,
                signup : {
                    location:formData.selectedLocation,
                    box:formData.selectedSize,
                    signupSource:"WEB_SIGNUP"
                },
                temporaryPassword:formData.temporaryPassword
            }

            if(formData.tracking) customerPayload.tracking = formData.tracking;

            await FunctionsApiService.customersUpdate(customerPayload);
            setIsLoading(false);

            history.push(rootPath + nextPath);

        } catch (error) {
            Sentry.captureException(error);
            setErrorMessage(t('error'));
            setIsLoading(false);
        }

    }, [history, rootPath, nextPath, formData.temporaryPassword, formData.billingAddress, formData.address, formData.company,formData.firstName, formData.lastName, formData.professionalTitle, formData.salutation, formData.phone, formData.tracking, formData.email, formData.selectedLocation, formData.selectedSize, setIsLoading, setErrorMessage, t]);

    const onConfirm = useCallback(async () => {
        if(isLoading) return;

        setIsLoading(true);

        try {

            let tmpPw = formData.temporaryPassword;

            if(rootPath === signupPaths.signupRoot){
                if(formData.email !== formData.validatedEmail) {
                    const emailValidation = await FunctionsApiService.customersValidateEmail({email: formData.email, sendLink: true});

                    if (emailValidation.data.processed === "pending" || emailValidation.data.processed === "pending_no_password") {
                        setShowDialogType(DIALOG_TYPE.TEMPORARY_USER)
                        setIsLoading(false);
                        return;
                    }

                    if (emailValidation.data.processed === "error") {
                        setShowDialogType(DIALOG_TYPE.EXISTING_USER)
                        setIsLoading(false);
                        return;
                    }

                    tmpPw = generateRandomString(12);

                    updateFormData({
                        email: formData.email,
                        validatedEmail: formData.email,
                        temporaryPassword: tmpPw,
                    });

                    await registerUser(formData.email, tmpPw);
                } else {
                    onUpdateCustomerData();
                }
            // registering a user automatically creates a customer. once the customer is created, the useEffect below is triggered and the flow continues
            } else {
                onUpdateCustomerData();
            }

        } catch (error) {
            Sentry.captureException(error);
            setErrorMessage(t('error'));
            setIsLoading(false);
        }

    }, [rootPath, onUpdateCustomerData, formData.temporaryPassword, formData.email, formData.validatedEmail, updateFormData, setIsLoading, isLoading, setErrorMessage, t])

    const onBack = useCallback(() => {
        history.replace(rootPath + prevPath);
    }, [history, rootPath, prevPath]);

    useEffect(() => {
        // when the auth user is newly created (in signup), we have to wait until the customer actually exists before we update it
        if(rootPath === signupPaths.signupRoot){
            if(customer?.email && !customer.firstName && !customer.signup) {
                onUpdateCustomerData();
            }
        } else {
            if(customer?.email){
                updateFormData({
                    email: customer.email,
                    validatedEmail: customer.email,
                });
            }
        }
    }, [customer, onUpdateCustomerData, rootPath, updateFormData])

    const onOpenWebApp = useCallback(()=>{
        window.location.href = process.env.REACT_APP_WEB_APP_LINK;
    }, []);

    const isFormValid =
        !!formData.salutation &&
        isValidFirstName(formData.firstName) &&
        isValidLastName(formData.lastName) &&
        (rootPath !== signupPaths.signupRoot || isValidEmail(formData.email));

    return (
        <S.View>
            <PageHeaderLinearFlow
                activeStep={activeStep}
                totalSteps={totalNumSteps}
                decrementActiveStep={onBack}
                progressBrackets={progressBrackets}
                onConfirm={onConfirm}
                isConfirmEnabeled={isFormValid}
                showBackButton={!!prevPath}
            />
            <S.Content>

                <S.Description>
                    {t('enterContactDetails.subheading')}
                </S.Description>

                <FormSection marginTop={'16px'}>
                    <FormRow>
                        <Select label={t('enterContactDetails.salutation')}
                                disabled={false}
                                error={false}
                                options={FunctionsApiService.getSalutations().map( el => {return {value: el.value, label:t(el.value)}})}
                                onChange={(e)=>updateFormData({salutation:e.target.value})}
                                value={formData.salutation}
                                isValid={formData.salutation.length > 0}
                        />
                        <HorizontalSeparator/>
                        <Select label={t('enterContactDetails.professionalTitle')}
                                disabled={false}
                                error={false}
                                options={FunctionsApiService.getProfessionalTitles().map( el => {return {value: el.value, label:t(el.value)}})}
                                onChange={(e)=>updateFormData({professionalTitle:e.target.value})}
                                value={formData.professionalTitle}
                                isValid={formData.professionalTitle.length > 0}
                        />
                    </FormRow>
                </FormSection>
                <FormSection marginTop={'16px'}>
                    <FormRow>
                        <TextInput label={t('enterContactDetails.firstName')}
                                   disabled={false} error={false}
                                   onChange={(e)=>updateFormData({firstName:e.target.value})}
                                   value={formData.firstName}
                                   isValid={isValidFirstName(formData.firstName)}
                                   name={"given-name"}
                        />
                        <HorizontalSeparator/>
                        <TextInput label={t('enterContactDetails.lastName')}
                                   disabled={false} error={false}
                                   onChange={(e)=>updateFormData({lastName:e.target.value})}
                                   value={formData.lastName}
                                   isValid={isValidLastName(formData.lastName)}
                                   name={"family-name"}
                        />
                    </FormRow>

                    {rootPath === signupPaths.signupRoot &&
                        <FormRow marginTop={'16px'}>
                            <TextInput label={t('enterContactDetails.email.placeholder')}
                                       disabled={false}
                                       error={false}
                                       onChange={(e)=>updateFormData({email:e.target.value})}
                                       value={formData.email}
                                       isValid={isValidEmail(formData.email)}
                                       name={"email"}
                            />
                        </FormRow>
                    }

                    <FormRow marginTop={'16px'}>
                        <TextInput label={t('enterContactDetails.phone.placeholder')}
                                   disabled={false}
                                   error={false}
                                   onChange={(e)=>updateFormData({phone:e.target.value})}
                                   value={formData.phone}
                                   isValid={isValidPhonenumber(formData.phone)}
                                   name={"phone"}
                        />
                    </FormRow>

                    <FormRow marginTop={'16px'}>
                        <TextInput label={t('enterContactDetails.company')}
                                   disabled={false}
                                   error={false}
                                   onChange={(e)=>updateFormData({company:e.target.value})}
                                   value={formData.company}
                                   isValid={formData.company.length > 0}
                                   name={"company"}
                        />
                    </FormRow>
                </FormSection>

                {!isLoading
                    ? <ButtonPrimary isDisabled={!isFormValid}
                                     label={t('continue')}
                                     marginTop={32}
                                     onClick={onConfirm}
                    />
                    : <LoadingIndicator marginTop={24}/>
                }

                <FormRow marginTop={"48px"}>
                    <Label>{t('enterContactDetails.policy.part1')}
                        <a href='https://legal.asservato.de/datenschutz' target="_privacy" onClick={() => {trackEvent(EVENTS.PRIVACY_POLICY_VIEWED);}}>{t('enterContactDetails.policy.privacy')}</a>
                        {t('enterContactDetails.policy.part2')}
                    </Label>
                </FormRow>

            </S.Content>

            <DialogBasic isOpen={showDialogType === DIALOG_TYPE.EXISTING_USER}
                         handleClose={() => setShowDialogType(null)}
                         title={t('enterContactDetails.errorDialog.existingUser.title')}
                         description={t('enterContactDetails.errorDialog.existingUser.description')}
                         actions={[
                             {callback: () => setShowDialogType(null), label: t('enterContactDetails.errorDialog.existingUser.back')},
                             {callback: onOpenWebApp, label: t('enterContactDetails.errorDialog.existingUser.toLogin')},
                         ]}
            />

            <DialogBasic isOpen={showDialogType === DIALOG_TYPE.TEMPORARY_USER}
                         handleClose={() => setShowDialogType(null)}
                         title={t('enterContactDetails.errorDialog.temporaryUser.title')}
                         description={t('enterContactDetails.errorDialog.temporaryUser.description', {email:formData.email})}
                         actions={[
                             {callback: () => setShowDialogType(null), label: t('enterContactDetails.errorDialog.temporaryUser.ok')},
                         ]}
            />
        </S.View>
    );
}

const FormSection = styled.div`
    margin-top: ${props => props.marginTop || null};
`;

const FormRow = styled.div`
    margin-top: ${props => props.marginTop || null};
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const HorizontalSeparator = styled.div`
    min-width: 12px;
    flex: 1;
`;

const Label = styled(LabelRegularSmall)`
    a {
        color: ${colors.tertiary.tertiary_100};
    }
`;
